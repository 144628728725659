import firebase from "firebase"
import { navigate } from "gatsby"

import { handleSignOut } from "./signout"
import { isBrowser } from "services/general"
import { parseAuthErrorMessage } from "./messages"

export const getUserData = async ({ AUTH_UID }) => {
  const filteredUsers = await firebase
    .firestore()
    .collection("users")
    .where("authUid", "==", AUTH_UID)
    .get()
  let userData
  filteredUsers.forEach((user) => {
    userData = { ...user.data(), id: user.id }
  })

  return userData
}

export const getUserAddresses = async ({ addressesId }) => {
  let filteredAddress = await firebase
    .firestore()
    .collection("addresses")
    .doc(addressesId || " ")
    .get()
  if (filteredAddress.data())
    filteredAddress = {
      ...filteredAddress.data(),
      id: filteredAddress.id,
    }
  else filteredAddress = {}

  return filteredAddress
}

export const handleEmailLogin = async ({
  values,
  callBack,
  setMessage,
  resetForm,
  errorCallback,
  redirectTo,
}) => {
  let { email, password } = values
  try {
    let response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    const AUTH_UID = response?.user?.uid

    let userData = await getUserData({ AUTH_UID })
    let addressesId = userData?.addresses
    let userAddresses = await getUserAddresses({ addressesId })

    if (isBrowser()) {
      sessionStorage.setItem("userData", JSON.stringify({ ...userData }))
      sessionStorage.setItem("addresses", JSON.stringify(userAddresses))
    }

    resetForm()
    if (callBack) callBack()

    let roles =
      userData?.roles?.filter(
        (role) => role?.subdomain === process.env.GATSBY_AWS_S3_BUCKET_NAME
      ) || []

    if (roles?.length > 0) {
      // if (roles[0].status === "active" && response?.user?.emailVerified) {
      if (roles[0].status === "active") {
        if (userData?.forceReset)
          return navigate("/profile/update-password", {
            state: { forceReset: true },
          })
        if (redirectTo && redirectTo !== "null") {
          return navigate(`${redirectTo}`)
        }
        return navigate("/")
      } else if (roles[0].status === "deactivated") {
        return navigate("/enroll-patient")
      } else {
        handleSignOut({ redirect: false })
        setMessage({
          type: "success",
          content: {
            message:
              "Your enrollment is currently being processed and <b>will be ready in one working day</b>. Once your account is activated, you will be able to order your medicines using this platform.",
          },
        })
      }
    } else {
      return navigate("/enroll-patient")
    }
  } catch (error) {
    if (errorCallback) errorCallback()
    setMessage({
      type: "danger",
      content: {
        code: error.code,
        message: parseAuthErrorMessage(error.message),
      },
    })
  }
}

export const handleVerifyName = async ({
  values,
  callBack,
  setMessage,
  resetForm,
  errorCallback,
  locationState,
}) => {
  try {
    let hasMatchingName = false
    const { redirectTo } = locationState

    if (isBrowser()) {
      if (
        values.lastName.replace(/ /g, "").toLowerCase() ===
        locationState.lastName.replace(/ /g, "").toLowerCase()
      ) {
        hasMatchingName = true
      }
      sessionStorage.setItem("hasVerifiedName", JSON.stringify(hasMatchingName))
    }

    resetForm()
    if (callBack) callBack()

    if (redirectTo && redirectTo !== "null" && hasMatchingName) {
      return navigate(`${redirectTo}`)
    } else {
      setMessage({
        type: "danger",
        content: {
          message: "The last name you have entered is incorrect.",
        },
      })
    }
  } catch (error) {
    if (errorCallback) errorCallback()
    setMessage({
      type: "danger",
      content: {
        code: error.code,
        message: parseAuthErrorMessage(error.message),
      },
    })
  }
}
