import React, { useState, useEffect, useContext } from "react"
import { Link, navigate } from "gatsby"
import { Formik, Form } from "formik"
import classNames from "classnames"

import AuthForm from "./AuthForm"
import Button from "elements/Button"
import Message from "elements/Message"
import Captcha from "elements/Captcha"
import Layout from "layout/Layout"
import Container from "layout/Container"

import { AppContext } from "../../context/AppContext"
import { isBrowser } from "services/general"
import { handleAuthSubmit } from "./services/auth"
import { hasSignedInUser } from "./services/user"
import { authValidationSchema } from "./utils/authValidationSchema"

const Auth = (props) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const [isCaptchaNotSolved, setIsCaptchaNotSolved] = useState(true)
  const { state, dispatch } = useContext(AppContext)
  let locationState = props?.location?.state
  let { pageContext } = props
  let { module } = pageContext

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true)

    let payload = {
      values,
      resetForm,
      setMessage,
      setLoading,
      module: module.name,
      locationState: props.location.state || locationState,
    }

    handleAuthSubmit(payload, dispatch)
  }

  // Gets locationState from session storage if there is nothing in location state
  if (isBrowser() && !locationState) {
    locationState = JSON.parse(sessionStorage.getItem("locationState"))
  }

  // Prevents locationState from going away if the page gets refreshed
  useEffect(() => {
    if (isBrowser()) {
      const existingLocationState = sessionStorage.getItem("locationState")
      if (!existingLocationState || existingLocationState === "null") {
        sessionStorage.setItem(
          "locationState",
          JSON.stringify(props.location.state)
        )
      }
    }
  }, [locationState])

  useEffect(() => {
    if (isBrowser() && hasSignedInUser()) navigate("/epharmacy/order")

    if (!state.auth.hasBeenVerified && module.name === "sign-up")
      navigate("/verify-email")
    if (state.auth.hasBeenVerified && module.name === "sign-in") {
      setMessage({
        type: "danger",
        content:
          "This email is already a registered CarePlus account. Please sign in using these credentials.",
      })

      dispatch({ type: "RESET_DETAILS" })
    }
  }, [])

  return (
    <Layout
      title={module.title}
      seoTitle={module.seoTitle}
      subtitle={module.subtitle}
      isSignInPage={module?.isSignInPage}
    >
      <Container isCentered desktop={5} fullhd={5}>
        <Formik
          initialValues={state.auth}
          validationSchema={authValidationSchema(module.name)}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form method="post">
              <AuthForm module={module.name} values={values} />
              {module.name === "sign-in" && (
                <p className="is-size-6 pb-1 pt-0">
                  <Link to="/forgot-password">Forgot password</Link>
                </p>
              )}
              {message?.content && (
                <Message color={message?.type}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: message?.content?.message || message?.content,
                    }}
                  />
                </Message>
              )}
              <Captcha setIsCaptchaNotSolved={setIsCaptchaNotSolved} />
              <Button
                type="submit"
                color="primary"
                size="medium"
                disabled={loading || isCaptchaNotSolved}
                className={classNames({ "is-loading": loading })}
                isFullwidth
              >
                {module.cta}
              </Button>
              {module.name === "sign-in" && (
                <section>
                  <p className="has-text-centered is-size-5 pt-1">
                    Don't have an account yet?{" "}
                    <Link to="/verify-email">Enroll as Patient</Link>
                  </p>
                </section>
              )}
              {module.name !== "sign-in" && module.name !== "recover-email" && (
                <section>
                  <p className="has-text-centered is-size-7 pt-1">
                    By signing up, you agree to our{" "}
                    <Link to="/terms-and-conditions">Terms</Link> and{" "}
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </p>
                  <p className="has-text-centered is-size-6 mt-2">
                    Already have an account? <Link to="/sign-in">Sign In.</Link>
                  </p>
                </section>
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default Auth
